import styled from "styled-components"

export const StyledTitle = styled.h3`
    padding: 0;
    margin: 0;

    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;

    color: #000000;
`
