export const getTextDependOnPercent = (number: number) => {
    if (number === 0) {
        return "Impossible"
    }
    if (number <= 0.001) {
        return "Almost impossible"
    }
    if (number <= 1) {
        return "Extremely unlikely"
    }
    if (number <= 5) {
        return "Highly unlikely"
    }
    if (number <= 10) {
        return "Unlikely"
    }
    if (number <= 40) {
        return "Somewhat unlikely"
    }
    if (number <= 40) {
        return "Inconclusive"
    }
    if (number < 90) {
        return "Somewhat likely"
    }
    if (number < 95) {
        return "Likely"
    }
    if (number < 99) {
        return "Highly likely"
    }
    if (number < 99.999) {
        return "Extremely likely"
    }
    if (number < 100) {
        return "Almost certain"
    }

    return "Certain"
}

export const getColorDependsOnPosition = (position: number) => {
    if (position % 6 === 0) return "#FF5722"
    if (position % 5 === 0) return "#03A9F4"
    if (position % 4 === 0) return "#C2185B"
    if (position % 3 === 0) return "#FBC02D"
    if (position % 2 === 0) return "#7C4DFF"

    return "#13C384"
}
