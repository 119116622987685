import { StyledTitle } from "./SideTitle.styles"

type Props = {
    children: JSX.Element | string | Element
}

const SideTitle = ({ children }: Props) => {
    return <StyledTitle>{children}</StyledTitle>
}

export default SideTitle
