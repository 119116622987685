import { Tweet } from "../../types"
import TweetComponent from "./TweetComponent"
import { useSelector } from "react-redux"
import { AppState } from "../../redux/reducers"
import Box from "../../reusableComponents/Box"
import SideTitle from "../../reusableComponents/SideTitle"
import { StyledLatestTweets } from "./LatestTweets.styles"

const LatestTweets = () => {
    const { tweets } = useSelector((state: AppState) => state.main)

    return (
        <StyledLatestTweets>
            {!!tweets && tweets.length > 0 && (
                <Box>
                    <>
                        <div className="title">
                            <SideTitle>Latest Tweets</SideTitle>
                        </div>
                        {tweets.slice(0, 3).map((tweet: Tweet, index) => (
                            <TweetComponent tweet={tweet} key={tweet.id} lastTweet={index === 2} />
                        ))}
                    </>
                </Box>
            )}
        </StyledLatestTweets>
    )
}

export default LatestTweets
