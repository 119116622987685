import styled from "styled-components"

export const StyledLatestTweets = styled.div`
    .title {
        margin-bottom: 10px;
    }
`

export const StyledTweet = styled.div<{ lastTweet: boolean }>`
    display: flex;
    border-bottom: 1px solid #ececec;
    margin-bottom: ${({ lastTweet }) => !lastTweet && "10px"};

    .image {
        width: 24px;
        margin-right: 20px;
        margin-top: 12px;
    }

    .text {
        flex: 1;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.2em;

        a {
            color: #000000;
            text-decoration: none;
        }
    }

    .date {
        font-size: 16px;
        line-height: 1;

        color: #6e6d79;

        margin: ${({ lastTweet }) => (lastTweet ? "10px 0 0" : "10px 0")};
    }

    & :last-child {
        border: none;
    }

    @media (max-width: 575px) {
        .image {
            margin-top: 5px;
        }
    }
`
