import Box from "../../reusableComponents/Box"
import SideTitle from "../../reusableComponents/SideTitle"
import { StyledNewsMentions } from "./NewsMentions.styles"
import { newsMentions } from "../../constants/newsMentions"

import { useState } from "react"
import ShowMoreButton from "../../reusableComponents/ShowMoreButton"

const NewsMentions = () => {
    const [showAll, setShowAll] = useState(false)

    const toggleShowAll = () => setShowAll(!showAll)

    return (
        <StyledNewsMentions>
            <Box>
                <>
                    <div className="title">
                        <SideTitle>Mentions</SideTitle>
                    </div>
                    {!!newsMentions &&
                        !!newsMentions?.length &&
                        newsMentions.map(({ title, source, link }, index) => {
                            return index < 3 || showAll ? (
                                <div key={index} className="item">
                                    <a
                                        href={link}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="link"
                                    >
                                        <h5 className="newsTitle">{title}</h5>
                                        <div className="newsSource">{source}</div>
                                    </a>
                                </div>
                            ) : null
                        })}
                    <div className="button">
                        <ShowMoreButton toggleShowAll={toggleShowAll} showAll={showAll} />
                    </div>
                </>
            </Box>
        </StyledNewsMentions>
    )
}

export default NewsMentions
