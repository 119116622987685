import Image from "next/image"
import { StyledHomeImage } from "./HomeImage.styles"
import ButtonPrimary from "../../reusableComponents/ButtonPrimary"
import { useDispatch } from "react-redux"
import { addModal } from "../../redux/actions/ui/ui"
import { SIGN_UP_MODAL } from "../../constants/modals"
import SignUpModal from "../Header/Modals/SignUpModal"
import LiteYouTubeEmbed from "react-lite-youtube-embed"

const HomeImage = () => {
    const dispatch = useDispatch()

    const openSignUp = () =>
        dispatch(addModal({ id: SIGN_UP_MODAL, children: <SignUpModal />, size: "auto" }))

    const socialBlock = (
        <div className="socialNetworks">
            <a href="https://www.facebook.com/rootclaim/" target="_blank" rel="noreferrer">
                <Image
                    src="/icons/social/facebookOutlined.svg"
                    alt="facebook"
                    width={30}
                    height={30}
                />
            </a>
            <a href="https://www.linkedin.com/company/rootclaim" target="_blank" rel="noreferrer">
                <Image
                    src="/icons/social/linkedinOutlined.svg"
                    alt="facebook"
                    width={30}
                    height={30}
                />
            </a>
            <a href="https://twitter.com/rootclaim" target="_blank" rel="noreferrer">
                <Image
                    src="/icons/social/twitterOutlined.svg"
                    alt="facebook"
                    width={30}
                    height={30}
                />
            </a>
        </div>
    )

    const joinButton = (
        <div className="button">
            <ButtonPrimary onClick={openSignUp}>Join Community</ButtonPrimary>
        </div>
    )

    return (
        <StyledHomeImage>
            {/* <div className="background">
                <Image
                    src="/images/background.jpg"
                    alt="background"
                    layout="fill"
                    priority
                    decoding="sync"
                />
            </div> */}
            {/* <div className="contentWrapper"> */}
            <div className="leftSide">
                <h1 className="title">
                    Rootclaim.
                    <br />
                    Calculating reality
                </h1>
                <p className="description">
                    Read and share ideas from independent voices, world-class publications, and
                    experts from around the globe. Everyone's welcome.
                </p>
                <div className="tabletHide">{joinButton}</div>
            </div>
            <div className="rightSide">
                <div className="youtube">
                    <LiteYouTubeEmbed
                        id="B4_DkIoPGXk"
                        params="rel=0&modestbranding=0"
                        title="Is the media really helping us make sense of the world?"
                    />
                </div>
                <div className="tabletHide">{socialBlock}</div>
            </div>
            <div className="tabletShow">
                {joinButton}
                {socialBlock}
            </div>
            {/* </div> */}
        </StyledHomeImage>
    )
}

export default HomeImage
