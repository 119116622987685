import { StyledLoader } from "./Loader.styles"

const Loader = () => {
    return (
        <StyledLoader>
            <div className="loader" />
        </StyledLoader>
    )
}

export default Loader
