import { CardAnalysis, Story } from "../../types"
import Link from "next/link"
import ScenariosBlock from "./ScenariosBlock"
import Box from "../../reusableComponents/Box"
import { StyledMainPageStory } from "./MainPageStory.styles"
import ButtonPrimary from "../../reusableComponents/ButtonPrimary"
import Image from "next/image"
import { format } from "date-fns"

type Props = { story: Story | CardAnalysis; index: number }

const MainPageStory = ({ index, ...props }: Props) => {
    const {
        comments_count,
        question,
        scenarios,
        slug: slugUrl,
        created_at,
        updated_at = "",
        isclaim,
        url,
        id,
        isCardAnalysis,
    } = props.story

    const image_url = props?.story?.cover_photo?.image_url
    const alt_text = props?.story?.cover_photo?.alt_text

    const slug = slugUrl || url || id

    const href = isCardAnalysis
        ? `https://cards.rootclaim.com/analysis/${id}`
        : isclaim
        ? `/claims/${slug}`
        : `/analysis/${slug}`

    const optimizedImageUrl = image_url && image_url.replace("upload/", "upload/h_1000,c_scale/")

    return (
        <StyledMainPageStory>
            <Box>
                <>
                    <Link href={href}>
                        <a className="link">
                            <div className="boxHero">
                                <div className="heroBackground">
                                    <Image
                                        src={optimizedImageUrl || "/images/background.jpg"}
                                        alt={alt_text || ""}
                                        layout="fill"
                                        className="image"
                                        priority={!index}
                                        {...(!index && { decoding: "sync" })}
                                    />
                                </div>
                                <div className="heroForeground">
                                    <div className="title">
                                        <div
                                            className="titleText"
                                            dangerouslySetInnerHTML={{
                                                __html: question.replace(
                                                    new RegExp("<[^>]*>", "g"),
                                                    " "
                                                ),
                                            }}
                                        />
                                    </div>
                                    <div className="subtitle">
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <span>
                                                Published:{" "}
                                                {format(new Date(created_at), "LLL dd, yyyy")}
                                            </span>
                                            {updated_at && updated_at !== created_at && (
                                                <span className="updatedDateText">
                                                    Last update:{" "}
                                                    {format(new Date(updated_at), "LLL dd, yyyy")}
                                                </span>
                                            )}
                                        </div>
                                        <span>
                                            <span className="marginRight">
                                                <Image
                                                    width={16}
                                                    height={16}
                                                    src="/icons/comment.svg"
                                                    alt="comment"
                                                />
                                            </span>
                                            {comments_count}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </Link>

                    {!!scenarios && scenarios.length > 0 && (
                        <ScenariosBlock scenarios={scenarios} href={href} />
                    )}

                    {!!scenarios && scenarios?.length > 3 && (
                        <Link href={href}>
                            <a className="link">
                                <div>
                                    <ButtonPrimary block>{`View ${
                                        scenarios.length - 3
                                    } more`}</ButtonPrimary>
                                </div>
                            </a>
                        </Link>
                    )}
                </>
            </Box>
        </StyledMainPageStory>
    )
}

export default MainPageStory
