import Box from "../../reusableComponents/Box"
import { StyledBlogPosts } from "./BlogPosts.styles"
import { useSelector } from "react-redux"
import { AppState } from "../../redux/reducers"
import BlogItem from "./BlogItem"
import SideTitle from "../../reusableComponents/SideTitle"
import ButtonLink from "../../reusableComponents/ButtonLink"

const BlogPosts = () => {
    const { last_blog_posts } = useSelector((state: AppState) => state.main)

    return (
        <StyledBlogPosts>
            <Box>
                <>
                    <div className="mainTitle">
                        <SideTitle>Blog</SideTitle>
                    </div>
                    {!!last_blog_posts?.length &&
                        last_blog_posts.map((blogItem, index) =>
                            index < 3 ? <BlogItem key={blogItem.id} blogItem={blogItem} /> : null
                        )}
                    <div className="button">
                        <ButtonLink type="main">
                            <a href="https://blog.rootclaim.com/" target="_blank" rel="noreferrer">
                                Show more
                            </a>
                        </ButtonLink>
                    </div>
                </>
            </Box>
        </StyledBlogPosts>
    )
}

export default BlogPosts
