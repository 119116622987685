import React from "react"
import { StyledContainer } from "../src/styles/Home.styles"
import { useDispatch, useSelector } from "react-redux"
import {
    getBlogPosts,
    getCardAnalyses,
    getLastTweets,
    getShortAnalysisListPublic,
} from "../src/services"
import { AppState } from "../src/redux/reducers"
import Layout from "../src/reusableComponents/Layout"
import Head from "next/head"
import { GetServerSideProps } from "next"
import { BlogPost, CardAnalysis, Story, Tweet } from "../src/types"
import InfiniteScroll from "react-infinite-scroll-component"
import Loader from "../src/reusableComponents/Loader"
import { getShortAnalysisActions } from "../src/redux/actions/main/main"
import ButtonPrimary from "../src/reusableComponents/ButtonPrimary"
import { useEffect, useMemo, useState } from "react"
import { checkEdit, isBrowser, isMobile, isTablet } from "../src/helpers"
import LoaderDiv from "../src/components/LoaderDiv"
import { initialUiState } from "../src/redux/reducers/uiReducer"
import LayoutEdit from "../src/reusableComponents/LayoutEdit"
import basicAuthMiddleware from "nextjs-basic-auth-middleware"
import { IncomingMessage, ServerResponse } from "http"

// components
import LatestTweets from "src/components/LatestTweets"
import BlogPosts from "src/components/BlogPosts"
import NewsMentions from "src/components/NewsMentions"
import HomeImage from "src/components/HomeImage"
import MainPageStory from "src/components/MainPageStory"

type Props = {
    initialReduxState: {
        main: {
            main_page_stories: Story[]
            tweets: Tweet[]
            last_blog_posts: BlogPost[]
            card_analyses: CardAnalysis[]
        }
    }
}

export const getServerSideProps: GetServerSideProps<Props> = async ({ req, res }) => {
    res.setHeader("Cache-Control", "public, s-maxage=43200, stale-while-revalidate=59")
    const {
        headers: { host },
    } = req
    const { ADD_BASIC_AUTH } = process.env

    if (ADD_BASIC_AUTH) {
        await basicAuthMiddleware(req as IncomingMessage, res as ServerResponse)
    }

    const isEdit = checkEdit(host)
    const emptyFunc = (): {
        result: { main_page_stories: []; main_page_stories_counter: 0 }
    } => ({
        result: { main_page_stories: [], main_page_stories_counter: 0 },
    })

    const userAgent = req.headers["user-agent"]

    const isTablet = () =>
        userAgent &&
        /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
            userAgent?.toLowerCase()
        )
    const isMobile = () => userAgent && userAgent.toLowerCase().includes("mobile")

    const [
        {
            result: { main_page_stories, main_page_stories_counter },
        },
        { result: tweets },
        { result: last_blog_posts },
        { result: card_analyses = [] },
    ] = await Promise.all([
        isEdit
            ? emptyFunc()
            : getShortAnalysisListPublic({
                  limit: 5,
                  offset: 0,
                  device: isTablet() ? "tablet" : isMobile() ? "mobile" : "desktop",
              }),
        getLastTweets(),
        getBlogPosts(),
        getCardAnalyses(),
    ])

    return {
        props: {
            initialReduxState: {
                ui: { ...initialUiState, isEdit },
                main: {
                    main_page_stories,
                    main_page_stories_counter,
                    tweets,
                    last_blog_posts,
                    card_analyses,
                },
            },
        },
    }
}

const Home: React.FC = () => {
    const { main_page_stories, main_page_stories_counter, mainPageLoader, card_analyses } =
        useSelector((state: AppState) => state.main)

    const { access_token } = useSelector((state: AppState) => state.admin)
    const { isEdit } = useSelector((state: AppState) => state.ui)

    const [width, setWidth] = useState(0)

    const stories = useMemo(
        () =>
            [...(main_page_stories || []), ...(card_analyses || [])].sort(
                (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
            ),

        [main_page_stories, card_analyses]
    )

    const counter = useMemo(
        () => (main_page_stories_counter || 0) + (card_analyses || []).length,
        [main_page_stories_counter, card_analyses]
    )

    const dispatch = useDispatch()

    const loadMore = () => {
        dispatch(
            getShortAnalysisActions.request({
                limit: 6,
                offset: stories?.length || 0,
                device: isTablet() ? "tablet" : isMobile() ? "mobile" : "desktop",
            })
        )
    }

    const reportWindowSize = () => {
        setWidth(window.innerWidth)
    }

    useEffect(() => {
        if (!isBrowser()) return
        window.addEventListener("resize", reportWindowSize)
        return () => window.removeEventListener("resize", reportWindowSize)
    })

    useEffect(() => {
        if (!access_token || !isEdit || stories?.length) {
            return
        }

        dispatch(
            getShortAnalysisActions.request({
                limit: 5,
                offset: 0,
                device: isTablet() ? "tablet" : isMobile() ? "mobile" : "desktop",
            })
        )
    }, [access_token, isEdit, JSON.stringify(stories)])

    return (
        <>
            <Head>
                <title>Home | Rootclaim</title>
                <link rel="icon" href="/favicon.ico" />

                <meta
                    name="description"
                    content="Rootclaim helps people understand complex issues by combining the power of crowdsourced information with the mathematical validity of statistics."
                />

                <meta property="og:type" content="article" />
                <meta property="og:url" content="https://www.rootclaim.com/" />
                <meta property="og:title" content="Rootclaim. Calculating reality." />
                <meta
                    property="og:description"
                    content="Rootclaim helps people understand complex issues by combining the power of crowdsourced information with the mathematical validity of statistics."
                />
                <meta property="og:updated_time" content="1483880740" />
                <meta
                    property="og:image"
                    content="http://res.cloudinary.com/dpowy5tkw/image/upload/v1493121178/calculating_reality.png"
                />

                <meta
                    property="og:image:secure_url"
                    content="http://res.cloudinary.com/dpowy5tkw/image/upload/v1493121178/calculating_reality.png"
                />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />

                <meta property="fb:app_id" content="410563205820220" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Rootclaim. Calculating reality." />
                <meta
                    name="twitter:description"
                    content="Rootclaim helps people understand complex issues by combining the power of crowdsourced information with the mathematical validity of statistics."
                />
                <meta name="twitter:creator" content="@Rootclaim" />
                <meta
                    name="twitter:image:src"
                    content="http://res.cloudinary.com/dpowy5tkw/image/upload/v1493121178/calculating_reality.png"
                />

                <meta name="robots" content="index, follow" />
            </Head>

            <LayoutEdit>
                <Layout withoutSideMenu>
                    {stories?.length ? (
                        <>
                            <HomeImage />
                            <StyledContainer>
                                <div className="leftSide">
                                    {!isMobile() && (
                                        <div className="desktop">
                                            {!!stories &&
                                                stories.length > 0 &&
                                                stories
                                                    .slice(0, 3)
                                                    .map((story, index) => (
                                                        <MainPageStory
                                                            story={story}
                                                            key={story.id}
                                                            index={index}
                                                        />
                                                    ))}
                                        </div>
                                    )}

                                    {isMobile() && (
                                        <div className="mobile">
                                            {!!stories &&
                                                stories.length > 0 &&
                                                stories.map((story, index) => (
                                                    <MainPageStory
                                                        story={story}
                                                        key={story.id}
                                                        index={index}
                                                    />
                                                ))}
                                            {mainPageLoader && (
                                                <div className="loaderWrapper">
                                                    <Loader />
                                                </div>
                                            )}
                                            {(stories?.length || 0) < (counter || 0) && (
                                                <div className="loadMoreButton">
                                                    <ButtonPrimary onClick={loadMore}>
                                                        Load more
                                                    </ButtonPrimary>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                                <div className="rightSide">
                                    <BlogPosts />
                                    <NewsMentions />
                                    <LatestTweets />
                                </div>

                                {!isMobile() && (
                                    <div className="desktop">
                                        {(!width || width > 992) && (
                                            <InfiniteScroll
                                                dataLength={stories?.length || 0}
                                                next={loadMore}
                                                hasMore={(stories?.length || 0) < (counter || 0)}
                                                loader={null}
                                            >
                                                <div className="twoColumns">
                                                    {!!stories &&
                                                        stories.length > 0 &&
                                                        stories
                                                            .slice(3)
                                                            .map((story, index) => (
                                                                <MainPageStory
                                                                    story={story}
                                                                    key={story.id}
                                                                    index={index}
                                                                />
                                                            ))}
                                                </div>
                                                {mainPageLoader && (
                                                    <div className="loaderWrapper">
                                                        <Loader />
                                                    </div>
                                                )}
                                            </InfiniteScroll>
                                        )}
                                    </div>
                                )}
                            </StyledContainer>
                        </>
                    ) : (
                        <LoaderDiv />
                    )}
                </Layout>
            </LayoutEdit>
        </>
    )
}

export default Home
