import { BlogPost } from "../../types"
import { format } from "date-fns"
import { StyledBlogItem } from "./BlogPosts.styles"

type Props = { blogItem: BlogPost }

const BlogItem = ({ blogItem }: Props) => {
    const {
        date,
        link,
        title: { rendered: title },
    } = blogItem

    //new blogpost don't have "wp:featuredmedia" under _embedded object
    const image =
        blogItem._embedded?.["wp:featuredmedia"] &&
        blogItem._embedded?.["wp:featuredmedia"][0]?.media_details?.sizes.medium?.source_url

    return (
        <StyledBlogItem>
            <img src={image} alt="blog" />
            <a href={link} target="_blank" rel="noreferrer" className="sideSection">
                <div className="title">{title}</div>
                <div className="date">{format(new Date(date), "LLL dd, yyyy")}</div>
            </a>
        </StyledBlogItem>
    )
}

export default BlogItem
