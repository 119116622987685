import styled from "styled-components"
import { colors } from "../../../../constants/colors"

export const StyledEvidenceComponent = styled.div<{ rtl: boolean }>`
    border-bottom: 1px solid ${colors.lineGrey};
    padding-bottom: 10px;

    &:last-child {
        border-bottom: none;
    }

    .titleWrapper {
        background-color: ${colors.subHeadingBg};
        padding: 14px 27px 11px;
    }

    .contentWrapper {
        display: flex;
        padding: 16px 27px 10px;

        &.rtl {
            .content {
                padding-left: 20px;
            }
        }
    }

    .content {
        flex: 1;
        position: relative;
        font-weight: 400;

        p {
            margin-top: 0;
            line-height: 1.3;
        }
    }

    .effect {
        width: 45%;
    }

    .titleWithIcon {
        align-items: center;
        display: inline-flex;
        margin-top: 3px;

        > div {
            margin: 0 5px;
        }

        .icon {
            margin-bottom: 7px;
        }

        img {
            height: 16px;
            width: 16px;
        }
    }

    .smallTitleWithIcon {
        display: flex;
        align-items: center;
        margin-top: 20px;

        .effect-icon > div {
            margin: 0 5px;
        }

        .icon {
            margin-bottom: 7px;
        }

        img {
            height: 16px;
            width: 16px;
        }
    }

    .smallTitleWithIcon > .titleIcon {
        display: flex;
        align-items: center;
        margin: auto 8px 6px;
    }

    a > span {
        color: inherit !important;
        font: inherit !important;
        text-decoration: inherit !important;
    }

    .confidenceWrapper {
        margin: 0 30px 10px;
    }

    .centeredShowMore {
        border-top: 1px solid ${colors.lineGrey};
        margin: 0 30px;
        padding-top: 11px;
        text-align: center;
    }
    .marginBottom {
        margin-bottom: 10px;
    }

    .float {
        float: ${({ rtl }) => (rtl ? "left" : "right")};
    }

    .inlineNameLink {
        cursor: default;
        display: inline;
        color: ${colors.blue};
        font-size: 12px;
        vertical-align: super;
        unicode-bidi: isolate;
    }

    .inline-children {
        > * {
            display: inline;
        }
    }

    .source-item {
        display: inline;

        &:last-child {
            .comma-separator {
                display: none;
            }
        }
    }

    .sensitivity-wrapper {
        padding: 0 27px 10px;
    }

    .show-more-sensitivity {
        padding: 0 0 10px;
    }

    .sensitivity-section-heading {
        margin: 20px 0;
    }

    @media (max-width: 767px) {
        .effect {
            width: 100%;
        }

        .showMoreButtonWrapper {
            position: static;
        }

        .content {
            padding-bottom: 0;
        }
    }

    @media (max-width: 575px) {
        .centeredShowMore {
            margin: 0 20px;
        }
    }
`

export const StyledEffect = styled.div<{ rtl: boolean }>`
    font-family: "Montserrat", sans-serif;
    .columnOne {
        flex: 1;

        > p {
            font-size: 14px;
        }
    }

    .chartItemText {
        font-size: 13.5px;
        font-weight: 400;
    }

    .effect-icon > div {
        margin: 0 5px;

        > .icon div {
            margin: 0;
        }
    }

    .columnTwo {
        margin: 0 12px 0 0;
        min-width: 86px;
        width: 86px;

        &.min-max-col {
            margin: 0;
            min-width: unset;
            width: fit-content;
        }

        > div p {
            font-size: 14px;
        }

        .effect-icon {
            .icon {
                margin: 0 0 0 12px;
                min-width: unset;
            }
        }
    }
    .columnTwo > span {
        display: flex;
        justify-content: center;
    }

    .columnThree {
        width: 50%;
        display: flex;
        align-items: center;

        > .bar-wrapper {
            padding: 0;
        }
    }

    .rightSideTitle {
        font-size: 14px;
        font-weight: 700;
        margin: 0;
    }

    .barItem {
        margin-bottom: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .barItem:first-child {
        margin-top: 28px;
    }

    .barItem:last-child {
        margin-bottom: 0;
    }

    .min-max-wrapper {
        display: flex;
        font-weight: 600;
        font-size: 14px;
        padding: 0 10px;

        .divider {
            order: 1;
            padding: 0 3px;
        }

        .min {
            order: 0;
        }

        .max {
            order: 2;
        }

        .percent {
            order: 3;
        }
    }

    .bar-wrapper {
        padding: 0 10px;
        width: 100%;

        &.with-tooltip {
            & > span,
            .previewTitleWhite {
                width: 100%;
            }

            b {
                font-weight: bold !important;
            }
        }
    }

    @media (max-width: 830px) {
        .columnThree {
            width: 38%;
        }
    }

    @media (max-width: 767px) {
        width: 100%;
    }
`

export const StyledSubEvidence = styled.div<{ firstElement: boolean; rtl: boolean }>`
    background: #ffffff;
    border: 1px solid #d8d8d8;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 14px 14px 10px;
    margin-top: ${({ firstElement }) => (firstElement ? "0" : "10")}px;

    p {
        margin-block-start: 0;
        margin-block-end: 0;
    }

    .nameJSX {
        margin-bottom: 14px;
        font-family: "Montserrat", sans-serif;
    }

    .buttonWrapper {
        margin-top: 12px;
    }

    .string + * {
        margin-top: 14px;
    }
    .source {
        margin-top: 0;
    }

    .link {
        vertical-align: super;
        font-size: 16px;
    }

    .confidenceWrapper {
        margin: 0;
    }
`

export const StyledBestExplanation = styled.div<{ rtl: boolean }>`
    background: #ffffff;
    border: 1px solid #d8d8d8;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 14px 14px 0;
    margin-top: 0;
    margin-bottom: 28px;

    .centeredShowMore {
        padding-bottom: 10px;
    }

    .best-explanation-content {
        overflow: auto;
    }

    p {
        margin-block-start: 0;
        margin-block-end: 0;
    }

    .subtitle {
        padding: 12px 0 14px;
    }

    table {
        border-collapse: collapse;
        margin-bottom: 10px;
        min-width: fit-content;

        td:nth-child(2) {
            min-width: 300px;
        }
    }

    td {
        border: 1px solid #d8d8d8;
        padding: 10px 12px;
    }
`

export const StyledTitleIcon = styled.div`
    height: fit-content;
    margin-left: 6px;
    min-width: 16px;
    display: flex;
`

export const StyledConfidence = styled.div<{ rtl?: boolean }>`
    display: inline-block;
    .percent {
        margin-left: 7px;
    }
    .titleWithIcon {
        display: inline;
    }
`
