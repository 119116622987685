import styled from "styled-components"

export const StyledAnalysisHeader = styled.div<{
    singleHeader?: boolean
    invertIconColor?: boolean
}>`
    align-items: end;
    display: flex;

    img {
        height: 24px;
        filter: ${({ invertIconColor }) => (invertIconColor ? "invert(1)" : "invert(0)")};
        object-fit: contain;
        position: relative;
        top: 3px;
        width: 24px;
    }

    h2 {
        align-items: baseline;
        display: flex;
        font-size: 18px;
        color: #515151;
    }

    @media (max-width: 767px) {
        .title {
            width: ${({ singleHeader }) => (singleHeader ? "unset" : "min-content")};
        }
        .icon {
            margin-right: 0;
        }
    }

    @media (max-width: 424px) {
        .title {
            font-size: 21px;
        }

        h2 {
            display: flex;
            flex-wrap: wrap;
        }
    }
`
