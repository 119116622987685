import { StyledBox } from "./Box.styles"

type Props = {
    children: JSX.Element | string | Element
}

const Box = ({ children }: Props) => {
    return <StyledBox>{children}</StyledBox>
}

export default Box
