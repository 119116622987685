import { roundNumberHomePage } from "../../helpers"
import { StyledPercent } from "./PercentItem.styles"
import TooltipLight from "../TooltipLight"
import { StyledH4 } from "../../styles/Text/Text.styles"
import TooltipDark from "../TooltipDark"
import { StyledTitleIcon } from "../../components/Analise/EvidenceContainer/EvidenceComponent/EvidenceComponent.styles"
import Image from "next/image"

type Props = {
    percent: number
    analysis?: boolean
    rtl?: boolean
    showTooltip?: boolean
    tooltip?: string
}

const PercentItem = ({ percent, analysis = false, rtl, showTooltip, tooltip }: Props) => {
    return (
        <StyledPercent analysis={analysis}>
            <div className="number">
                {!!percent && (
                    <div className="number-tooltip-wrapper">
                        <TooltipLight
                            text={<StyledH4 rtl={rtl}>{`${Number(percent.toFixed(2))}%`}</StyledH4>}
                            title={`${roundNumberHomePage(percent)}%`}
                            size="auto"
                            hideTitle={true}
                        />
                    </div>
                )}
                <div className="outcome-tooltip-wrapper">
                    {showTooltip && tooltip && tooltip.length > 0 && (
                        <TooltipDark
                            icon={
                                <StyledTitleIcon>
                                    <Image src="/icons/info.svg" width={16} height={16} />
                                </StyledTitleIcon>
                            }
                            width="medium"
                            position={rtl ? "" : "left"}
                            revert={rtl}
                        >
                            {tooltip}
                        </TooltipDark>
                    )}
                </div>
            </div>
        </StyledPercent>
    )
}

export default PercentItem
