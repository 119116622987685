import styled from "styled-components"
import { colors } from "../../constants/colors"

export const StyledPercent = styled.div<{ analysis: boolean }>`
    align-items: center;
    min-width: ${({ analysis }) => (analysis ? "282px" : "180px")};
    width: ${({ analysis }) => (analysis ? "282px" : "180px")};
    display: flex;
    justify-content: center;
    position: relative;

    .number {
        color: ${colors.grey};
        display: flex;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 1.375;
        margin-top: 8px;
        margin-left: 10px;
        margin-right: ${({ analysis }) => (analysis ? "10px" : "30px")};
        position: relative;

        @media (max-width: 575px) {
            margin-top: 0;
        }
    }

    .text {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 1.375;
        color: #6e6d79;
    }

    @media (max-width: 767px) {
        .number {
            margin-right: ${({ analysis }) => (analysis ? "10px" : "10px")};
        }

        .number,
        .text {
            font-size: 20px;
        }
        min-width: ${({ analysis }) => (analysis ? "268px" : "182px")};
        width: ${({ analysis }) => (analysis ? "268px" : "182px")};
    }

    @media (max-width: 575px) {
        padding: ${({ analysis }) => (analysis ? "unset" : "0 0 0 8px")};

        ${({ analysis }) =>
            analysis ? "min-width: 70px; width: 70px;" : "width: unset; min-width: unset;"}
    }
`
