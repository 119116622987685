import styled from "styled-components"

export const StyledNewsMentions = styled.div`
    margin-bottom: 20px;

    .title {
        margin-bottom: 10px;
    }

    .item {
        margin-bottom: 16px;
    }

    .link {
        text-decoration: none;
    }

    .newsTitle {
        margin: 0px 0 4px 0;

        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 1.2em;

        color: #000000;
    }

    .newsSource {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;

        color: #6e6d79;
    }

    .button {
        margin-top: -4px;
    }

    @media (max-width: 767px) {
        .newsTitle,
        .newsSource {
            font-size: 16px;
        }
    }

    @media (max-width: 575px) {
        .newsTitle {
            margin: 20px 0 8px 0;
        }
    }
`

export const StyledImage = styled.img<{ rotate: string }>`
    height: 9px;
    margin-bottom: 5px;
    transform: ${({ rotate }) => (rotate === "true" ? "rotate(180deg)" : "rotate(0deg)")};
    width: 14px;
`
