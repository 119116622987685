import { Scenario } from "../../../types"
import React, { useMemo } from "react"
import ScenarioItem from "./ScenarioItem"
import { StyledScenariosBlock } from "./ScenariosBlock.styles"
import AnalysisHeader from "../../../reusableComponents/AnalysisHeader"
import Link from "next/link"
import { v4 as uuidv4 } from "uuid"

type Props = { scenarios: Scenario[]; href: string }

const ScenariosBlock = ({ scenarios, href }: Props) => {
    const sortedScenarios = useMemo(
        () => scenarios.sort((a, b) => b.net_prob - a.net_prob),
        [JSON.stringify(scenarios)]
    )
    return (
        <StyledScenariosBlock>
            <div className="headingWrapper">
                <AnalysisHeader title={"Hypotheses Considered"}>
                    <>
                        Each competing hypothesis is one possible answer to the question above. All
                        of the hypotheses must be mutually exclusive (two can’t be true at the same
                        time), and the Rootclaim system assumes that they are collectively
                        exhaustive (there are no other possible answers to consider). As a group,
                        the competing hypotheses capture all the main points of view surrounding the
                        topic under debate.{" "}
                        <Link href="/how-rootclaim-works">
                            <a>(Read more)</a>
                        </Link>
                    </>
                </AnalysisHeader>
                <div className="rightSideHeader">
                    <AnalysisHeader title={"Calculated Results"} position="left">
                        <>
                            This is not a poll. These are the actual likelihoods of each hypothesis.
                            A conclusion of 90% means that out of 100 hypotheses that received a 90%
                            likelihood, we expect 90 to be true, and 10 to be false. See actual
                            performance of past Rootclaim analyses{" "}
                            <Link href="/rootclaim-track-record">
                                <a>here</a>
                            </Link>
                            . This conclusion is the result of a probabilistic inference model that
                            takes into account all available evidence, bypassing the{" "}
                            <Link href="/white-paper">
                                <a>flaws of human reasoning</a>
                            </Link>
                            . Read more about our{" "}
                            <Link href="/how-rootclaim-works">
                                <a>model</a>
                            </Link>
                            .
                        </>
                    </AnalysisHeader>
                </div>
            </div>
            <Link href={href}>
                <a className="link">
                    <div className="scenarioContent">
                        {sortedScenarios.slice(0, 3).map((scenario, index) => (
                            <ScenarioItem scenario={scenario} key={uuidv4()} position={index + 1} />
                        ))}
                    </div>
                </a>
            </Link>
        </StyledScenariosBlock>
    )
}

export default ScenariosBlock
