import Loader from "../../reusableComponents/Loader"
import { StyledLoaderDiv } from "./LoaderDiv.styles"
import { isBrowser } from "../../helpers"

const LoaderDiv = () => {
    const headerHeight = isBrowser() && document.getElementById("header")?.offsetHeight
    const footerHeight = isBrowser() && document.getElementById("footer")?.offsetHeight

    return (
        <StyledLoaderDiv blocksHeight={(headerHeight || 0) + (footerHeight || 0)}>
            <Loader />
        </StyledLoaderDiv>
    )
}

export default LoaderDiv
