// types
import { Scenario } from "src/types"

// utils
import { removeSpaceSymbol } from "src/helpers"

// components
import PercentItem from "../../../reusableComponents/PercentItem"

// styles
import { StyledScenarioItem } from "./ScenariosBlock.styles"

type Props = {
    scenario: Scenario
    position: number
}

const ScenarioItem = ({
    position,
    scenario: { text, net_prob, name, nickname, short_name },
}: Props) => {
    return (
        <StyledScenarioItem position={position}>
            <span className="position">{position}</span>
            <div className="flex">
                <p>
                    <b>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: short_name || nickname || name || "",
                            }}
                        />
                        {(!!text?.trim() || !!name?.trim()) && ": "}
                    </b>
                    <span
                        dangerouslySetInnerHTML={{
                            __html:
                                (text && removeSpaceSymbol(text)) ||
                                (name && removeSpaceSymbol(name)),
                        }}
                        className={"string"}
                    />
                </p>
            </div>
            <PercentItem percent={net_prob} />
        </StyledScenarioItem>
    )
}

export default ScenarioItem
