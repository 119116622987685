import styled from "styled-components"

export const StyledContainer = styled.div`
    padding: 20px 2%;
    background: #fbfbfd;
    position: relative;
    .leftSide {
        width: 60%;
    }

    .rightSide {
        width: 36%;
        position: absolute;
        top: 20px;
        right: 2%;
    }

    .twoColumns {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        & > * {
            width: 49%;
        }
    }

    .loaderWrapper {
        position: relative;
        height: 100px;
    }
    .loadMoreButton {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
    }

    @media (max-width: 1024px) {
        padding: 20px;

        .leftSide {
            margin-right: 20px;
        }
    }

    @media (max-width: 992px) {
        .leftSide {
            margin-right: 0;
            width: 100%;
        }

        .rightSide {
            position: unset;
            width: 100%;
        }
    }

    @media (max-width: 767px) {
        padding: 0 15px;
    }
`
