import styled from "styled-components"
import { colors } from "../../constants/colors"

export const StyledMainPageStory = styled.div`
    margin-bottom: 30px;

    .image {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    .heroForeground {
        .title {
            * {
                background-color: transparent !important;
                text-shadow: 2px 3px 3px #000;
                line-height: 1.25em !important;
                font-style: normal !important;
                font-weight: 600 !important;
                font-size: 36px !important;
                color: #fff !important;
                text-align: left !important;
                margin: 0 !important;
            }
        }
    }
    .title {
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 1.25em;
        color: #000000;
        text-decoration: none;

        p {
            margin: 0;
        }
    }

    .titleText {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        max-height: 134px;

        @media (max-width: 575px) {
            max-height: 85px;
        }
    }

    .updatedDateText {
        font-size: 13px;
    }

    .titleWrapper {
        display: flex;
        margin-bottom: 27px;
    }

    .titleRightSide {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
    }

    .commentsBlock {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        line-height: 19px;
        color: #6e6d79;
    }

    .marginRight {
        margin-right: 6px;
    }

    .link {
        color: inherit;
    }

    .boxHero {
        align-items: flex-end;
        border-radius: 10px;
        box-sizing: border-box;
        display: flex;
        height: 352px;
        margin-bottom: 5px;
        overflow: hidden;
        position: relative;
    }

    .heroBackground {
        height: 352px;
        width: 100%;
    }

    .heroForeground {
        background-color: ${colors.overlayBackground};
        box-sizing: border-box;
        color: ${colors.white};
        padding: 12px 30px;
        position: absolute;
        width: 100%;
        z-index: 9;
        max-width: calc(100% - 20px);
        border-radius: 5px;
        margin: 0 auto 10px;
        right: 0;
        left: 0;

        p,
        div {
            color: ${colors.white};
        }

        .subtitle {
            display: flex;
            justify-content: space-between;
            font-size: 16px;

            img {
                filter: invert(1);
                height: 14px;
            }
        }
    }

    @media (max-width: 575px) {
        .title {
            font-size: 22px;
            line-height: 1.3;
            margin: 16px 0 0;
            text-shadow: none;
        }

        .heroBackground {
            border-radius: 5px;
            height: 190px;
            overflow: hidden;
            div,
            img {
                position: unset !important;
                height: 100%;
                width: 100%;
                transform: none;
            }
        }

        .heroForeground {
            .title {
                text-shadow: none;
            }

            .title {
                font-size: 22px;
                line-height: 1.3;
                margin: 16px 0 0;
                text-shadow: none;

                * {
                    color: ${colors.black} !important;
                    font-size: 22px !important;
                    line-height: 1.3 !important;
                    margin: 16px 0 0 !important;
                    text-shadow: none !important;
                }
            }
        }

        .boxHero {
            flex-direction: column;
            height: fit-content;
            margin-bottom: 0;
        }

        .heroForeground {
            background-color: transparent;
            color: ${colors.black};
            margin-bottom: 16px;
            padding: 0;
            position: static;

            p,
            div {
                color: ${colors.black} !important;
            }

            .subtitle {
                img {
                    filter: none;
                }
            }
        }
    }
`
