import { StyledImage } from "../../components/NewsMentions/NewsMentions.styles"
import ButtonLink from "../ButtonLink"

type Props = {
    showAll: boolean
    toggleShowAll: () => void
    translate?: string
    showTitle?: string
    closeTitle?: string
}

const ShowMoreButton = ({ showAll, toggleShowAll, translate, showTitle, closeTitle }: Props) => {
    return (
        <ButtonLink
            type="main"
            suffix
            onClick={toggleShowAll}
            block
            centered
            rtl={translate === "heb"}
        >
            <>
                {translate === "heb"
                    ? showAll
                        ? "פחות"
                        : "עוד"
                    : `${showAll ? closeTitle || "Show less" : showTitle || "Show more"}`}
                <StyledImage
                    alt="arrow down"
                    src="/icons/arrowDown.svg"
                    rotate={showAll.toString()}
                />
            </>
        </ButtonLink>
    )
}

export default ShowMoreButton
