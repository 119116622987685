import styled from "styled-components"
import { getColorDependsOnPosition } from "../../../constants/percents"
import { colors } from "../../../constants/colors"

export const StyledScenariosBlock = styled.div`
    .scenarioContent {
        margin-bottom: 10px;
    }

    .headingWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #e5e5e5;
        border-radius: 5px;
        position: relative;
        padding-left: 40px;
        padding-bottom: 5px;
        margin-bottom: 5px;
        padding-top: 10px;

        img {
            width: 18px;
            padding-top: 5px;
        }
        h2 .title {
            color: ${colors.black};
            font-size: 16px;
        }
        .rightSide > * {
            margin-left: 30px;
        }
        .rightSideHeader {
            width: 180px;
        }
    }

    @media (max-width: 767px) {
        .title {
            width: auto;
        }
    }
    @media (max-width: 575px) {
        .headingWrapper {
            padding-bottom: 6px;
            padding-left: 6px;
        }

        .headingWrapper {
            .rightSideHeader {
                width: auto;
            }
            .title {
                margin: unset;
            }
        }
    }

    @media (max-width: 450px) {
        .title {
            width: min-content;
        }
    }
`

export const StyledScenarioItem = styled.div<{ position: number }>`
    display: flex;
    align-items: center;

    box-sizing: border-box;
    margin-bottom: 5px;

    border-color: #d8d8d8 #d8d8d8 #d8d8d8 ${({ position }) => getColorDependsOnPosition(position)};
    border-style: solid;
    border-width: 1px 1px 1px 5px;
    border-radius: 5px;
    min-height: 72px;

    p {
        font-weight: 400;
        margin: 10px 0;
    }

    .position {
        font-style: normal;
        font-size: 24px;
        font-weight: 500;
        line-height: 1.375;
        color: ${({ position }) => getColorDependsOnPosition(position)};
        margin: 0 15px 0 10px;
    }

    .flex {
        flex: 1;
    }

    .string {
        display: inline;

        * {
            display: inline;
            color: inherit !important;
            font-size: 18px !important;
            font-weight: 400 !important;
        }
    }

    @media (max-width: 767px) {
        font-size: 16px;

        .position {
            font-size: 16px;
        }
    }

    @media (max-width: 575px) {
        padding: 10px 8px 10px 0;

        .position {
            margin: 0 10px 0 10px;
        }

        p {
            margin: 0;
        }
    }
`
