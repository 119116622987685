import styled from "styled-components"

export const StyledBlogPosts = styled.div`
    margin-bottom: 20px;

    .mainTitle {
        margin-bottom: 10px;
    }
`

export const StyledBlogItem = styled.div`
    display: flex;
    margin-bottom: 12px;

    img {
        width: 125px;
        height: 75px;
        object-fit: cover;
        border-radius: 3px;
    }

    .sideSection {
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        text-decoration: none;
    }

    .title {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 1.2em;

        color: #000000;
    }

    .date {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;

        color: #6e6d79;
    }

    @media (max-width: 767px) {
        .title {
            font-size: 16px;
        }
    }

    @media (max-width: 575px) {
        flex-direction: column;
        margin-top: 17px;

        img {
            height: 150px;
            margin-bottom: 18px;
            width: 100%;
        }

        .title {
            margin-bottom: 10px;
        }

        .sideSection {
            margin-left: 0;
        }
    }
`
