import { StyledH2 } from "../../styles/Text/Text.styles"
import { StyledAnalysisHeader } from "./AnalysisHeader.styles"
import TooltipDark from "../TooltipDark/TooltipDark"
import React, { ReactElement } from "react"
import { colors } from "../../constants/colors"

type Props = {
    title: string | ReactElement
    children?: React.ReactNode
    position?: "center" | "" | "left"
    revert?: boolean
    singleHeader?: boolean
    width?: "large"
    hideTooltip?: boolean
    color?: keyof typeof colors
    weight?: "bold" | 600 | "normal" | 700 | 500 | 400
}

const AnalysisHeader = ({
    title,
    children,
    position,
    revert,
    width,
    singleHeader,
    hideTooltip,
    color = "white",
    weight = 700,
}: Props) => {
    return (
        <StyledAnalysisHeader singleHeader={singleHeader} invertIconColor={color === "white"}>
            <StyledH2 weight={weight} color={color} rtl={revert}>
                <span className="title d-block">{title}</span>
                {children && !hideTooltip && (
                    <TooltipDark position={position} revert={revert} width={width}>
                        {children}
                    </TooltipDark>
                )}
            </StyledH2>
        </StyledAnalysisHeader>
    )
}

export default AnalysisHeader
