import { Tweet } from "../../types"
import { format } from "date-fns"
import { StyledTweet } from "./LatestTweets.styles"
import Image from "next/image"

type Props = { tweet: Tweet; lastTweet: boolean }

const TweetComponent = ({ tweet, lastTweet }: Props) => {
    const { created_at = "", id, text } = tweet

    return (
        <StyledTweet lastTweet={lastTweet}>
            <div className="image">
                <Image src="/icons/twitter.svg" width={24} height={24} />
            </div>
            <div className="text">
                <a
                    href={`https://twitter.com/Rootclaim/status/${id}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    <div>{text}</div>
                </a>
                <div className="date">{format(new Date(created_at), "LLL dd, yyyy")}</div>
            </div>
        </StyledTweet>
    )
}

export default TweetComponent
