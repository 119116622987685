import styled from "styled-components"

export const StyledHomeImage = styled.div`
    padding: 60px 15px 30px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 50px;
    background: #fbfbfd;

    // .title,
    // .description {
    //     text-shadow: 2px 3px 3px #000000;
    // }

    .title {
        font-style: normal;
        font-weight: 700;
        font-size: 56px;
        line-height: 64px;
        margin: 0 0 16px 0;
        // color: #ffffff;
    }
    .leftSide {
        display: flex;
        flex-direction: column;
        max-width: 520px;
        justify-content: center;
    }

    .description {
        padding: 0;
        margin: 0;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 1.5;
        // color: #ffffff;
    }

    // .background {
    //     position: absolute;
    //     z-index: 1;
    //     width: 100%;
    //     height: 100%;

    //     &:before {
    //         background: rgba(0, 0, 0, 0.2);
    //         content: "";
    //         display: inline-flex;
    //         height: 100%;
    //         position: absolute;
    //         width: 100%;

    //         @media (max-width: 575px) {
    //             background: rgba(0, 0, 0, 0.35);
    //             z-index: 9;
    //         }
    //     }

    //     img {
    //         height: 100%;
    //         object-fit: cover;
    //         width: 100%;
    //     }
    // }

    .button {
        margin-top: 30px;
    }

    .youtube {
        width: 480px;
        margin: auto;
        max-width: 100%;

        > div {
            height: 0;
            padding-bottom: 56.25%;
            border-radius: 10px;
        }
    }

    .socialNetworks {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 10px 0;
        justify-content: flex-end;

        img {
            filter: invert(1);
        }
    }

    .tabletHide {
        display: block;
    }

    .tabletShow {
        display: none;
    }

    @media (max-width: 1024px) {
        flex-direction: column;
        align-items: stretch;
        gap: 30px;

        .tabletHide {
            display: none;
        }

        .tabletShow {
            display: flex;
            margin: auto;

            gap: 20px;
            width: auto;
            justify-content: center;
            align-items: center;
            .button {
                margin-top: 0;
            }
        }

        // .button {
        //     bottom: 30px;
        //     position: absolute;
        // }

        .leftSide {
            margin: auto;
        }

        .rightSide {
            display: flex;
            flex-direction: column;
        }

        .socialNetworks {
            margin-left: auto;
        }

        .title,
        .description {
            text-align: center;
        }
    }

    @media (max-width: 767px) {
        .description {
            font-size: 18px;
        }
    }

    @media (max-width: 575px) {
        .title {
            font-size: 28px;
            line-height: 1.3;
            margin-bottom: 12px;
        }

        .description {
            line-height: 1.4;
        }
    }
`
